import {Gateway} from "@/model/server/Gateway";
import {IRequester} from "@/model/ServerApi";
import {AuthModule} from "@/store/modules/auth";

export class GatewayServerApi {
    private cachedAll: Promise<Gateway[]>;

    constructor(private requester: IRequester) {
    }

    getAll(): Promise<Gateway[]> {
        return this.requester.get('/gateway/all');
    }

    getRelated(): Promise<Gateway[]> {
        let user = AuthModule.user;
        if (!user) {
            return Promise.resolve([]);
        }
        return this.getAll().then(gateways => gateways.filter(x => user.gateways.indexOf(x.id) >= 0));
    }

    add(gateway: Gateway): Promise<Gateway> {
        this.cachedAll = null;
        return this.requester.post('/gateway/add', gateway);
    }

    save(gateway: Gateway): Promise<Gateway> {
        this.cachedAll = null;
        return this.requester.post('/gateway/save', gateway);
    }
}
