import {IRequester} from "@/model/ServerApi";
import {GatewayToken} from "@/model/server/GatewayToken";

export class TokenApi {
    constructor(private requester: IRequester) {
    }

    getAll(): Promise<GatewayToken[]> {
        return this.requester.post('/token/getAll');
    }

    save(token: GatewayToken): Promise<GatewayToken> {
        return this.requester.post('/token/save', token);
    }

    add(token: GatewayToken): Promise<GatewayToken> {
        return this.requester.post('/token/add', token);
    }
}
