import {Rule} from "@/model/server/Rule";
import {ExpressionCheckerValue} from "@/model/server/ExpressionCheckerValue";
import {IRequester} from "@/model/ServerApi";
import {RuleExpressionHelp} from "@/model/server/RuleExpressionHelp";
import {ScoringLimit} from "@/model/server/ScoringLimit";
import {RuleInfo} from "@/model/server/RuleInfo";

export class RuleServerApi {
    constructor(private requester: IRequester) {
    }

    getRuleInfo(id: number): Promise<RuleInfo> {
        return this.requester.get('/rule/get?id=' + id);
    }

    getRelated(gateway: number): Promise<Rule[]> {
        return this.requester.get('/rule/getrelated?gateway=' + gateway);
    }

    getRuleLinks(gateway: number): Promise<{ [rule_id: number]: string }> {
        return this.requester.get('/rule/getrulelinks?gateway=' + gateway);
    }

    getAllRules(gateway: number): Promise<number[]>{
        return this.requester.get('/rule/getnotificationrules?gateway=' + gateway);
    }

    addRule(rule: Rule): Promise<Rule> {
        return this.requester.post('/rule/addrule', rule);
    }

    saveRule(rule: Rule): Promise<Rule> {
        return this.requester.post('/rule/saverule', rule);
    }

    removeRule(id: number): Promise<void> {
        return this.requester.post('/rule/removerule?id=' + id);
    }

    testExpression(expression: string, gateway: number, merchant: string): Promise<ExpressionCheckerValue> {
        return this.requester.post('/rule/testexpression', {expression, gateway, merchant});
    }

    help(language: string): Promise<RuleExpressionHelp> {
        return this.requester.get('/rule/help?language=' + language);
    }

    getScoringLimit(gateway: number): Promise<ScoringLimit> {
        return this.requester.get('/rule/getscoringlimit?gateway=' + gateway);
    }

    getScoringLimits(): Promise<ScoringLimit[]> {
        return this.requester.get('/rule/getscoringlimits');
    }

    setScoringLimit(limit: ScoringLimit): Promise<void> {
        return this.requester.post('/rule/setscoringlimit', limit);
    }
}
