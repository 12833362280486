import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import Cookies from 'js-cookie'
import { DataTools } from "@/model/DataTools";
import { ServerApi } from '@/model/ServerApi';
import { User } from "@/model/server/User";
import { Role } from "@/model/server/Role";
import { ViewStates } from "@/model/ViewStates";

const COOKIE_NAME = 'user';

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
class Auth extends VuexModule
{
    public token: string = Auth.getToken();
    public expireMoment: Date = Auth.getExpireMoment();
    public user: User = Auth.getUser();
    public viewStates: ViewStates = Auth.getViewStates();

    @Action({ commit: 'SET_STATE', rawError: true })
    public async login(params:{ username: string, password: string })
    {
        let data = await new ServerApi().login.login(params.username.trim(), params.password);
        return data.status === "OK" ? data : null;
    }

    @Action({ commit: 'SET_USER' })
    public updateUser(user:User)
    {
        return user;
    }

    @Action({ commit: 'SET_STATE' })
    public async logout()
    {
        return null;
    }

    @Action({ commit: 'SET_TOKEN' })
    public clearToken()
    {
        return null;
    }

    @Action({ commit: 'SET_VIEW_STATES' })
    setViewState(params: { name:string, data:string })
    {
        this.viewStates[params.name] = params.data;
        // noinspection JSIgnoredPromiseFromCall
        new ServerApi().user.setViewState(params.name, params.data);
        return this.viewStates;
    }

    @Mutation
    private SET_STATE(data:{ token: string, user: User, expireMoment:Date, viewStates:ViewStates }) : void
    {
        if (data) {
            Cookies.set(COOKIE_NAME, JSON.stringify({ token: data.token, user: data.user, expireMoment:data.expireMoment }));
            localStorage.setItem("viewStates", JSON.stringify(data.viewStates));
        }
        else{
            Cookies.remove(COOKIE_NAME);
            localStorage.removeItem("viewStates");
        }

        this.token = data ? data.token : null;
        this.expireMoment = data ? data.expireMoment : null;
        this.user = data ? data.user : null;
        this.viewStates = data ? (data.viewStates ? data.viewStates : {}) : {};
    }

    @Mutation
    private SET_TOKEN(token: string) : void
    {
        if (!Cookies.get(COOKIE_NAME)) {
            return;
        }
        let data = JSON.parse(Cookies.get(COOKIE_NAME));
        if (!data) { this.token = token; return; }
        this.token = data.token = token;
        Cookies.set(COOKIE_NAME, JSON.stringify(data));
    }

    @Mutation
    private SET_USER(user: User) : void
    {
        if (!Cookies.get(COOKIE_NAME)) {
            return;
        }
        let data = JSON.parse(Cookies.get(COOKIE_NAME));
        if (!data) { this.user = user; return; }
        this.user = data.user = user;
        Cookies.set(COOKIE_NAME, JSON.stringify(data));
    }

    @Mutation
    private SET_VIEW_STATES(viewStates: ViewStates) : void
    {
        this.viewStates = viewStates;
        localStorage.setItem("viewStates", JSON.stringify(viewStates));
    }

    private static getToken() : string
    {
        if (!Cookies.get(COOKIE_NAME)) {
            return null;
        }
        let data = JSON.parse(Cookies.get(COOKIE_NAME));
        return data ? data.token : null;
    }

    private static getExpireMoment() : Date
    {
        if (!Cookies.get(COOKIE_NAME)) {
            return null;
        }
        let data = JSON.parse(Cookies.get(COOKIE_NAME));
        return data ? DataTools.parseServerDate(data.expireMoment) : null;
    }

    private static getUser() : User
    {
        if (!Cookies.get(COOKIE_NAME)) {
            return null;
        }
        let data = JSON.parse(Cookies.get(COOKIE_NAME));
        return data ? data.user : null;
    }

    private static getViewStates() : ViewStates
    {
        if (!localStorage.getItem("viewStates")) {
            return {};
        }
        return JSON.parse(localStorage.getItem("viewStates"));
    }
}

export const AuthModule = getModule(Auth);

export class AuthTools
{
    public static hasRole(role:Role) : boolean
    {
        return AuthModule.token && AuthModule.user && AuthModule.user.options.indexOf(role) >= 0;
    }
}
