import {IRequester} from "@/model/ServerApi";
import {ReferenceCountriesModel} from "@/model/server/references/ReferenceCountriesModel";
import {ReferenceCountryPhoneCodeModel} from "@/model/server/references/ReferenceCountryPhoneCodeModel";
import {ReferenceTrustIndicatorModel} from "@/model/server/references/ReferenceTrustIndicatorModel";

export class ReferencesServerApi {

    constructor(private requester: IRequester){}

    getReferenceCountries(): Promise<ReferenceCountriesModel[]> {
        return this.requester.get('/references/getcountries');
    }

    createReferenceCountry(arg: ReferenceCountriesModel): Promise<boolean> {
        return this.requester.post('/references/createcountry', arg);
    }

    modifyReferenceCountry(arg: ReferenceCountriesModel): Promise<boolean> {
        return this.requester.post('/references/modifycountry', arg);
    }

    deleteReferenceCountry(code: string): Promise<boolean> {
        return this.requester.post('/references/deletecountry?code=' + code);
    }

    getReferenceCountryPhoneCodes(): Promise<ReferenceCountryPhoneCodeModel[]> {
        return this.requester.get('/references/getphonecodes');
    }

    createReferencePhoneCode(arg: ReferenceCountryPhoneCodeModel): Promise<boolean> {
        return this.requester.post('/references/createphonecode', arg);
    }

    modifyReferencePhoneCode(arg: ReferenceCountryPhoneCodeModel): Promise<boolean> {
        return this.requester.post('/references/modifyphonecode', arg);
    }

    deleteReferencePhoneCode(phoneCode: string): Promise<boolean> {
        return this.requester.post('/references/deletephonecode?pk=' + phoneCode);
    }

    getReferenceTrustIndicators(): Promise<ReferenceTrustIndicatorModel[]> {
        return this.requester.get('/references/gettrustindicators');
    }

    createReferenceTrustIndicator(arg: ReferenceTrustIndicatorModel): Promise<boolean> {
        return this.requester.post('/references/createtrustindicator', arg);
    }

    modifyReferenceTrustIndicator(arg: ReferenceTrustIndicatorModel): Promise<boolean> {
        return this.requester.post('/references/modifytrustindicator', arg);
    }

    deleteReferenceTrustIndicator(id: number): Promise<boolean> {
        return this.requester.post('/references/deletetrustindicator?id=' + id);
    }
}


