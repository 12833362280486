import {DataTools} from "@/model/DataTools";
import {TransactionChargebackResult} from "./TransactionChargebackResult";
import {TransactionChargebackStage} from "./TransactionChargebackStage";

export interface TransactionChargeback {
    stageRetrievalRequest: TransactionChargebackStage;
    stageChargeback: TransactionChargebackStage;
    stagePrearbitration: TransactionChargebackStage;
    stageArbitration: TransactionChargebackStage;

    ARN: string;
    RRN: string;
    comment: string;

    result: TransactionChargebackResult;

    chargeback_time: number;
}

export class TransactionChargebackTools {
    static clone(obj: TransactionChargeback): TransactionChargeback {
        return DataTools.cloneObject(obj);
    }

    static fixAfterLoading(chargeback: TransactionChargeback): TransactionChargeback {
        if (chargeback) {
            if (chargeback.stageRetrievalRequest) {
                TransactionChargebackTools.fixStage(chargeback.stageRetrievalRequest);
            }
            if (chargeback.stageChargeback) {
                TransactionChargebackTools.fixStage(chargeback.stageChargeback);
            }
            if (chargeback.stagePrearbitration) {
                TransactionChargebackTools.fixStage(chargeback.stagePrearbitration);
            }
            if (chargeback.stageArbitration) {
                TransactionChargebackTools.fixStage(chargeback.stageArbitration);
            }
        }
        return chargeback;
    }

    static newStage(): TransactionChargebackStage {
        return {
            receipt_date: null,
            decision_date: null,
            decision: null,
            RC: "",
            representment_date: null,
        };
    }

    private static fixStage(stage: TransactionChargebackStage): void {
        if (!stage) {
            return;
        }

        if (stage.receipt_date) {
            stage.receipt_date = DataTools.parseServerDate(stage.receipt_date);
        }

        if (stage.decision_date) {
            stage.decision_date = DataTools.parseServerDate(stage.decision_date);
        }

        if (stage.representment_date) {
            stage.representment_date = DataTools.parseServerDate(stage.representment_date);
        }
    }
}
