import {IRequester} from "@/model/ServerApi";
import {CardStatisticFilter} from "@/model/bindings/CardStatisticFilter";
import {CardStatisticFilterResponse} from "@/model/bindings/CardStatisticFilterResponse";
import {DataTools} from "@/model/DataTools";
import {CardStatistic} from "@/model/server/CardStatistic";

export class CardStatisticApi {
    constructor(private requester: IRequester) {
    }

    async getList(arg: CardStatisticFilter): Promise<CardStatisticFilterResponse> {
        let result: CardStatisticFilterResponse = await this.requester.post('/cardstatisticlist', arg);
        for (let t of result.card_statistics) {
            CardStatisticApi.fixElement(t);
        }
        return result;
    }

    private static fixElement(t: CardStatistic): CardStatistic {
        if (t.created) {
            t.created = DataTools.parseServerDate(t.created);
        }
        return t;
    }
}
