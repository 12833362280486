import {IRequester} from "@/model/ServerApi";
import {DataTools} from "@/model/DataTools";
import {Task} from "@/model/server/tasks/Task";
import {TaskCreateArg} from "@/model/bindings/TaskCreateArg";

export class TaskServerApi {
    constructor(private requester: IRequester) {
    }

    async getAll(): Promise<Task[]> {
        let tasks: Task[] = await this.requester.get('/task/list');
        for (let report of tasks) {
            report.create_time = DataTools.parseServerDate(report.create_time);
            report.start_time = report.start_time ? DataTools.parseServerDate(report.start_time) : null;
            report.finish_time = report.finish_time ? DataTools.parseServerDate(report.finish_time) : null;
        }
        return tasks;
    }

    submit(arg: TaskCreateArg): Promise<boolean> {
        return this.requester.post('/task/submit', arg);
    }

    cancel(id: number): Promise<boolean> {
        return this.requester.post('/task/cancel?id=' + id);
    }
}
