<template>
    <el-select :value="merchant" @input="emitInput" filterable :disabled="disabled">
        <el-option v-for="option in options" :key="option.value" :label="option.label + (option.value ? ' (' + option.value + ')' : '')" :value="option.value"/>
    </el-select>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import { Merchant } from "@/model/server/Merchant";
    import {ReferenceResponse} from "@/model/bindings/ReferenceResponse";

    @Component
    export default class MerchantSelector extends Vue
    {
        @Prop({ default:null }) gateway: number;
        @Prop({ default:null }) reference: string;
        @Prop({ default:null }) value: string;
        @Prop({ default:false }) disabled: boolean;
        @Prop({ default:false }) igoreReferenceRestrictions: boolean;


        @Watch("value", { immediate:true }) onValueChanged() { this.merchant = this.value; this.fixMerchant() }
        @Emit("input") emitInput(e:string) {}

        merchant: string = null;
        merchants: Merchant[] = null;
        references: ReferenceResponse = null;

        @Watch("gateway", { immediate:true }) onGatewayChanged() { this.fixMerchant() }
        @Watch("reference", { immediate:true }) onReferenceChanged() { this.fixMerchant2() }

        get options()
        {
            if (!this.merchants || !this.gateway ) {
                return [];
            }
            return [
                { value:null, label:"-" },
                ... this.merchants.filter(x => x.gateway === this.gateway
                    && (!this.igoreReferenceRestrictions && this.references != null &&
                        (
                            this.references.gateways == null
                            || this.references.gateways.length === 0
                            ||
                            (
                                this.references.gateways.includes(this.gateway)
                                && this.references.references.map(r => r.reference_id).includes(x.reference_id)
                            )
                        )
                    )
                    && (this.reference == null || x.reference_id === this.reference))
                        .map(x => ({ value:x.key_name, label:x.display_name }))
            ];
        }

        fixMerchant()
        {
            if (this.gateway && this.merchants && this.merchant) {
                if (!this.merchants.some(x => x.gateway === this.gateway && x.key_name === this.merchant)) {
                    this.emitInput(null);
                }
            }
        }
        fixMerchant2()
        {
            if (this.gateway && this.merchants && this.merchant) {
                if (!this.merchants.some(x => x.gateway === this.gateway && x.reference_id === this.reference && x.key_name === this.merchant)) {
                    this.emitInput(null);
                }
            }
        }

        created()
        {
            this.$serverApi.common.getReferences().then(refs => {this.references = refs;});
            this.$serverApi.merchant.getAll().then(merchants => {
                this.merchants = merchants;
                this.fixMerchant();
            });
        }
    }
</script>
