export const messages = {
    ru: {
        'Key': 'Ключ',
        'Add merchant': 'Добавить мерчанта',
        'Role':'Роль',
        'First txn at':'Первая транзакция',
        'Last txn at':'Последняя транзакция',
        'Business':'Бизнес',
        'Country': 'Страна',
        'Volume': 'Объем',
        'Emails':'Почт. адреса',
        'Company': 'Компания',
        'Country code': 'Код страны',
        'Manager': 'Менеджер',

        "Use ';' as delimiter.": "Используйте ';' в качестве разделителя.",

        "Merchant country distribution": "Распределение мерчантов по странам",
        "Merchant business distribution": "Распределение мерчантов по виду бизнеса",

        'MERCHANT_ROLE_MERCHANT':'Мерчант',
        'MERCHANT_ROLE_PS':'ПС',
        'MERCHANT_ROLE_ROYAL':'-',
        'MERCHANT_ROLE_TEST':'Тест',

        'MERCHANT_STATUS_TESTING' : 'Тестирование',
        'MERCHANT_STATUS_ACTIVE' : 'Активен',
        'MERCHANT_STATUS_INACTIVE' : 'Неактивен',

        'MERCHANT_BUSINESS_GAMBLING_EEA':'Гамблинг в ЕЭЗ',
        'MERCHANT_BUSINESS_GAMBLING_NON_EEA':'Гамблинг вне ЕЭЗ',
        'MERCHANT_BUSINESS_CRYPTO':'Крипта',
        'MERCHANT_BUSINESS_FOREX_EEA':'Форекс в ЕЭЗ',
        'MERCHANT_BUSINESS_FOREX_NON_EEA':'Форекс вне ЕЭЗ',
        'MERCHANT_BUSINESS_DATING':'Дейтинг',
        'MERCHANT_BUSINESS_OTHER' : 'Другой бизнес',
        'MERCHANT_BUSINESS_RETAIL_SHOPS':'Розничные магазины',
        'MERCHANT_BUSINESS_SKILL_GAMES':'Скилловые игры',
        'MERCHANT_BUSINESS_ADVERTISING_SERVICES':'Сервисы рекламы',
        'MERCHANT_BUSINESS_CASHBACK_SERVICES':'Сервисы кэшбеков',
    },
    en: {
        'MERCHANT_ROLE_MERCHANT':'Merchant',
        'MERCHANT_ROLE_PS':'PS',
        'MERCHANT_ROLE_ROYAL':'-',
        'MERCHANT_ROLE_TEST':'Test',

        'MERCHANT_STATUS_TESTING' : 'Testing',
        'MERCHANT_STATUS_ACTIVE' : 'Active',
        'MERCHANT_STATUS_INACTIVE' : 'Inactive',

        'MERCHANT_BUSINESS_GAMBLING_EEA':'Gambling EEA',
        'MERCHANT_BUSINESS_GAMBLING_NON_EEA':'Gambling non-EEA',
        'MERCHANT_BUSINESS_CRYPTO':'Crypto',
        'MERCHANT_BUSINESS_FOREX_EEA':'ForEx EEA',
        'MERCHANT_BUSINESS_FOREX_NON_EEA':'ForEx non-EEA',
        'MERCHANT_BUSINESS_DATING' : 'Dating',
        'MERCHANT_BUSINESS_OTHER' : 'Other',
        'MERCHANT_BUSINESS_RETAIL_SHOPS':'Retail shops',
        'MERCHANT_BUSINESS_SKILL_GAMES':'Skill games',
        'MERCHANT_BUSINESS_ADVERTISING_SERVICES':'Advertising services',
        'MERCHANT_BUSINESS_CASHBACK_SERVICES':'Cashback services',
    }
};
