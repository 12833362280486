import {User} from "@/model/server/User";
import {IRequester} from "@/model/ServerApi";
import {DataTools} from "@/model/DataTools";
import {PasswordChangeArg} from "@/model/api/PasswordChangeArg";

export class UserServerApi {
    constructor(private requester: IRequester) {
    }

    async getAll(): Promise<User[]> {
        let result: User[] = await this.requester.get('/user/all');
        for (let u of result) {
            UserServerApi.fixUser(u);
        }
        return result;
    }
    async logoff(): Promise<void> {
       await this.requester.get('/user/logoff');
    }
    async logoffall(): Promise<void> {
        await this.requester.get('/user/logoffall');
    }

    async add(user: User): Promise<User> {
        let addedUser = await this.requester.post('/user/add', user);
        return UserServerApi.fixUser(addedUser);
    }

    async save(user: User): Promise<User> {
        let savedUser = await this.requester.post('/user/save', user);
        return UserServerApi.fixUser(savedUser);
    }

    setViewState(name: string, data: string): Promise<void> {
        return this.requester.post('/user/setviewstate', {name: name, data: data});
    }

    changePassword(arg: PasswordChangeArg): Promise<boolean> {
        return this.requester.post('/user/changepassword', arg);
    }

    private static fixUser(u: User): User {
        if (u.last_login_time != null)
            {
                u.last_login_time = DataTools.parseServerDate(u.last_login_time);
            }

        if (u.password_change_time != null)
            {
                u.password_change_time = DataTools.parseServerDate(u.password_change_time);
            }

        return u;
    }
}
