<style scoped>
    .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
    }
</style>

<style src="@/assets/flags/flags.css"></style>

<template>

    <el-dropdown>
        <span class="el-dropdown-link">
            <span v-if="locale === 'ru'"><i class="flag flag-RU" /> RU</span>
            <span v-if="locale === 'en'"><i class="flag flag-UK" /> EN</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
                <div @click="selectRU"><i class="flag flag-RU" /> RU</div>
            </el-dropdown-item>
            <el-dropdown-item>
                <div @click="selectEN"><i class="flag flag-UK" /> EN</div>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>

</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { SmartTable, SmartColumn } from '@/components/smart-table';
    import Cookies from "js-cookie";

    @Component({
        components: { SmartTable, SmartColumn }
    })
    export default class LanguageSwitcher extends Vue
    {
        get locale() { return this.$i18n.locale }

        selectRU()
        {
            this.$i18n.locale = "ru";
            Cookies.set("locale", this.$i18n.locale);
        }

        selectEN()
        {
            this.$i18n.locale = "en";
            Cookies.set("locale", this.$i18n.locale);
        }
    }
</script>
