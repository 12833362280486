import dateFormat from 'dateformat';

export const REGEX_EMAIL = "(([^<>()\\[\\]\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\"]+)*)|(\".+\"))@(([^<>()[\\]\\.,;:\\s@\"]+\\.)+[^<>()[\\]\\.,;:\\s@\"]{2,})";

export class DataTools {
    static cloneObject<T>(obj: T, ignoreFields?: Array<string>): T {
        let r = <T>{};
        for (let field of Object.keys(obj)) {
            if (ignoreFields && ignoreFields.indexOf(field) >= 0) {
                continue;
            }
            r[field] = obj[field];
        }
        return r;
    }

    static equObjects<T>(a: T, b: T, ignoreFields?: Array<string>): boolean {
        if (a === b) {
            return true;
        }
        if (!a || !b) {
            return false;
        }

        let fieldsA = Object.keys(a).filter(x => !ignoreFields || ignoreFields.indexOf(x) < 0);
        let fieldsB = Object.keys(b).filter(x => !ignoreFields || ignoreFields.indexOf(x) < 0);
        if (!DataTools.equArrays(fieldsA, fieldsB)) {
            return false;
        }
        for (let field of fieldsA) {
            if (a[field] !== b[field]) {
                return false;
            }
        }
        return true;
    }

    static equArrays<T>(a: Array<T>, b: Array<T>, cmp?: (a: T, B: T) => boolean): boolean {
        if (a === b) {
            return true;
        }
        if (!a || !b) {
            return false;
        }

        if (a.length !== b.length) {
            return false;
        }

        if (!cmp) {
            cmp = (x, y) => x === y;
        }

        for (let i = 0; i < a.length; i++) {
            if (!cmp(a[i], b[i])) {
                return false;
            }
        }
        return true;
    }

    static sorted<T>(arr: Array<T>, cmp?: (a: T, B: T) => number): T[] {
        if (!arr) {
            return null;
        }
        let r = arr.slice(0);
        return r.sort(cmp);
    }

    static beautyNumber(x: number, addZerosAfterPoint = 0): string {
        if (x === null || typeof x === "undefined") {
            return "";
        }

        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        if (addZerosAfterPoint) {
            if (parts.length === 1) {
                parts.push("0".repeat(addZerosAfterPoint));
            }
            else {
                parts[1] += "0".repeat(Math.max(0, addZerosAfterPoint - parts[1].length));
            }
        }

        return parts.join(".");
    }

    static removeDuplicateItems<T>(arr: Array<T>, equ?: (a: T, b: T) => boolean): Array<T> {
        if (equ) {
            let r = [];
            for (let item of arr) {
                if (!r.some(x => equ(item, x))) {
                    r.push(item);
                }
            }
            return r;

        } else {
            return Array.from(new Set(arr));
        }
    }

    static dateToString(dt: Date, time: "no-time" | "hours-minutes" | "hours-minutes-seconds"): string {
        let s = "yyyy-mm-dd";

        if (time === "hours-minutes") {
            s = "yyyy-mm-dd HH:MM";
        }
        else {
            if (time === "hours-minutes-seconds") {
                s = "yyyy-mm-dd HH:MM:ss";
            }
        }

        return dateFormat(dt, s);
    }

    static dateAddMilliseconds(dt: Date, ms: number): Date {
        let r = new Date();
        r.setTime(dt.getTime() + ms);
        return r;
    }

    static dateAddMinutes(dt: Date, minutes: number): Date {
        return DataTools.dateAddMilliseconds(dt, minutes * 60 * 1000)
    }

    static round100(x: number) {
        if (x == null) {
            return null;
        }
        return Math.round(x * 100) / 100;
    }

    static durationToHumanString(minutes: number): string {
        let days = Math.floor(minutes / (24 * 60));
        minutes -= days * 24 * 60;

        let hours = Math.floor(minutes / 60);
        minutes -= hours * 60;

        minutes = Math.round(minutes);

        let parts = [];

        if (days) {
            parts.push(days + " " + DataTools.pluralize(days, "день", "дня", "дней"));
        }
        if (days < 5 && hours) {
            parts.push(hours + " " + DataTools.pluralize(hours, "час", "часа", "часов"));
        }
        if (!days && hours < 5 && minutes) {
            parts.push(minutes + " " + DataTools.pluralize(minutes, "минута", "минуты", "минут"));
        }

        return parts.length ? parts.join(" ") : "-";
    }

    static pluralize(n: number, one: string, two: string, five: string): string {
        n = Math.abs(n);
        n %= 100;
        if (n >= 5 && n <= 20) {
            return five;
        }
        n %= 10;
        if (n === 1) {
            return one;
        }
        if (n >= 2 && n <= 4) {
            return two;
        }
        return five;
    }

    static pluralizeWithNumber(n: number, one: string, two: string, five: string): string {
        return n + " " + DataTools.pluralize(n, one, two, five);
    }

    static compareStringsCaseInsensitive(a: string, b: string): number {
        if (!a && a !== "" && !b && b !== "") {
            return 0;
        }
        if (!a && a !== "") {
            return -1;
        }
        if (!b && b !== "") {
            return 1;
        }
        let aLC = a.toLocaleLowerCase();
        let bLC = b.toLocaleLowerCase();
        if (aLC < bLC) {
            return -1;
        }
        if (aLC > bLC) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }

    static compareUniversal(a: any, b: any): number {
        if (typeof a === "undefined" && typeof b === "undefined") {
            return 0;
        }
        if (typeof a === "undefined") {
            return -1;
        }
        if (typeof b === "undefined") {
            return 1;
        }

        if (a === null && b === null) {
            return 0;
        }
        if (a === null) {
            return -1;
        }
        if (b === null) {
            return 1;
        }

        if (typeof a == "number" && typeof b === "number"
            || a instanceof Date && b instanceof Date
        ) {
            return a < b ? -1 : (a > b ? 1 : 0)
        }

        a = a.toString();
        b = b.toString();

        return DataTools.compareStringsCaseInsensitive(a, b);
    }

    static numberToStringTrimmedToNoneZeroDigitsAfterPoint(x: number, digitsAfterPoint: number): string {
        let s = x + "";
        let i = s.indexOf('.');
        if (i < 0) {
            return s;
        }
        let j = i + 1;
        while (j < s.length && s[j] === '0') {
            j++;
        }
        return s.substring(0, Math.min(j + digitsAfterPoint, s.length));
    }

    static numberToStringTrimmedToDiffDigits(a: number, b: number, minSignedDigitsAfterPoint: number): string {
        if (a === b || !Number.isFinite(b)) {
            return Number.isFinite(a) ? DataTools.numberToStringTrimmedToNoneZeroDigitsAfterPoint(a, minSignedDigitsAfterPoint) : "-";
        }
        if (!Number.isFinite(a)) {
            return "-";
        }
        let start = 1;
        for (let i = 0; i < minSignedDigitsAfterPoint; i++) {
            start *= 10;
        }
        for (let i = start; i < 1000000000; i *= 10) {
            if (Math.round(a * i) !== Math.round(b * i)) {
                return (Math.round(a * i) / i) + "";
            }
        }
        return a + "";
    }

    static groupByString<T>(arr: T[], idSelector: (x: T) => string): { [id: string]: T[] } {
        let r = {};
        for (let item of arr) {
            let id = idSelector(item);
            if (!r[id]) {
                r[id] = [];
            }
            r[id].push(item);
        }
        return r;
    }

    static groupByNumber<T>(arr: T[], idSelector: (x: T) => number): { [id: number]: T[] } {
        let r = {};
        for (let item of arr) {
            let id = idSelector(item);
            if (!r[id]) {
                r[id] = [];
            }
            r[id].push(item);
        }
        return r;
    }

    static isBool(v: boolean) {
        // noinspection PointlessBooleanExpressionJS
        return v === true || v === false;
    }

    static parseServerDate(s: (string | Date)): Date {
        if (s instanceof Date) {
            return s;
        }
        return new Date(s);
    }

    static getUserTimeZoneMinutes(): number {
        return -(new Date().getTimezoneOffset());
    }

    static rtrim(str: string, chars: string): string {
        while (str.length > 0 && chars.indexOf(str[str.length - 1]) >= 0) {
            str = str.substring(0, str.length - 1);
        }
        return str;
    }

    static isExternalLink(path: string) {
        return /^(https?:|mailto:|tel:)/.test(path)
    }

    static getDeepField(obj: any, longPropertyName: string): any {
        if (!obj) {
            return null;
        }
        if (longPropertyName.indexOf(".") < 0) {
            return obj[longPropertyName];
        }
        let props = longPropertyName.split(".");
        return DataTools.getDeepField(obj[props[0]], props.slice(1).join("."));
    }

    static setDeepField(obj: any, longPropertyName: string, value: any): void {
        if (longPropertyName.indexOf(".") < 0) {
            obj[longPropertyName] = value;
            return;
        }
        let props = longPropertyName.split(".");
        if (!obj[props[0]]) {
            obj[props[0]] = {};
        }
        DataTools.setDeepField(obj[props[0]], props.slice(1).join("."), value);
    }
    static isCronValid(freq) {
        let cronregex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
        return cronregex.test(freq);
    }
}
