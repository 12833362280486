import {IRequester} from "@/model/ServerApi";
import {DataTools} from "@/model/DataTools";
import {Schedule} from "@/model/server/schedule/Schedule";

export class ScheduleServerApi {
    constructor(private requester: IRequester) {
    }

    async getAll(): Promise<Schedule[]> {
        let schedules: Schedule[] = await this.requester.get('/schedule/list');
        for (let schedule of schedules) {
            schedule.create_time = DataTools.parseServerDate(schedule.create_time);
            schedule.last_start_time = schedule.last_start_time == null ? null : DataTools.parseServerDate(schedule.last_start_time);
        }
        return schedules;
    }

    run(id: number): Promise<boolean> {
        return this.requester.post('/schedule/run?id=' + id);
    }

    switch(id: number): Promise<boolean> {
        return this.requester.post('/schedule/switch?id=' + id);
    }

    delete(id: number): Promise<boolean> {
        return this.requester.post('/schedule/delete?id=' + id);
    }

    save(request: Schedule): Promise<boolean>{
        return this.requester.post('/schedule/save', request);
    }
}
