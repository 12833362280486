import {Merchant} from "@/model/server/Merchant";
import {IRequester} from "@/model/ServerApi";
import {DataTools} from "@/model/DataTools";
import MerchantStatisticResponse from "@/model/bindings/MerchantStatisticResponse";

export class MerchantServerApi {
    private cachedAll: Merchant[];

    constructor(private requester: IRequester) {
    }

    async getAll(resetCashe:boolean = false): Promise<Merchant[]> {
        if (resetCashe) {
            this.cachedAll = null;
        }
        if (this.cachedAll) {
            return this.cachedAll;
        }

        let r: Merchant[] = await this.requester.get('/merchant/all');
        for (let i = 0; i < r.length; i++) {
            MerchantServerApi.parseDate(r[i]);
        }
        this.cachedAll = r;
        return r;

    }

    async add(merchant: Merchant): Promise<Merchant> {
        this.cachedAll = null;
        return MerchantServerApi.parseDate(await this.requester.post('/merchant/add', merchant));
    }

    async save(merchant: Merchant): Promise<Merchant> {
        this.cachedAll = null;
        return MerchantServerApi.parseDate(await this.requester.post('/merchant/save', merchant));
    }

    remove(id: number): Promise<void> {
        this.cachedAll = null;
        return this.requester.post('/merchant/remove?id=' + id);
    }

    async getStatistic(gatewayId: number): Promise<MerchantStatisticResponse> {
        return this.requester.post('/merchant/getstatistics?gatewayId=' + gatewayId);
    }

    private static parseDate(m: Merchant) {
        if (m) {
            if (m.first_transaction) {
                m.first_transaction = DataTools.parseServerDate(m.first_transaction);
            }
            if (m.last_transaction) {
                m.last_transaction = DataTools.parseServerDate(m.last_transaction);
            }
        }
        return m;
    }
}
