<style scoped>
    fieldset {
        height: 325px;
        overflow-x: auto;
        overflow-y: auto;
        margin-bottom: 15px;
    }
</style>

<template>
    <el-dialog :title="$t('Table options')" :visible.sync="innerVisible" :top="getTop()" :close-on-click-modal="false" width="400px" style="text-align: left">

        <el-form v-if="innerOptions"
                 ref="form"
                 :show-message="false"
                 label-width="0"
                 @submit.native.prevent="save"
        >
            <fieldset>
                <legend>{{ $t('Columns visibility') }}</legend>

                <el-form-item v-for="column in columns" size="mini" :key="column.value">
                    <!--suppress JSUnresolvedVariable -->
                    <el-switch :disabled="column.alwaysVisible" :value="innerOptions.indexOf(column.value) >= 0" @input="innerOptions = $event ? [...innerOptions, column.value] : innerOptions.filter(x => x !== column.value)"/> {{ column.label }}
                </el-form-item>
            </fieldset>

            <div class="el-dialog-form-buttons">
                <el-button native-type="submit" type="primary">{{ $t('OK') }}</el-button>
                <el-button @click="close">{{ $t('Cancel') }}</el-button>
            </div>

        </el-form>

    </el-dialog>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Emit, Watch, PropSync } from 'vue-property-decorator';
    import { messages } from "./messages";
    import { TimeInterval } from "@/components/time-interval";
    import { GatewayMerchantSelector } from "@/components/gateway-merchant-selector";
    import { MerchantDialog } from "@/components/merchant-dialog";
    import {SelectItemVisible} from "@/model/SelectItemVisible";

    @Component({
        components: { TimeInterval, GatewayMerchantSelector, MerchantDialog },
        i18n: { messages }
    })
    export default class TableOptionsDialog extends Vue
    {
        @PropSync('visible') innerVisible: boolean;

        @Prop() columns: SelectItemVisible[];

        innerOptions: string[] = [];
        @Prop({ default:[] }) options: string[];
        @Watch("options") onOptionsChanged() { this.innerOptions = this.options }
        @Emit("update:options") updateOptions(e) {}

        getTop() { return Math.max(0, (window.innerHeight - 650) / 2) + "px" }

        save()
        {
            this.updateOptions(this.innerOptions);
            this.close();
        }

        close()
        {
            this.innerVisible = false;
        }
    }
</script>
