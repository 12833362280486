<!--suppress JSCheckFunctionSignatures, JSUnresolvedVariable -->

<style scoped>
    .header-label {
        width: calc(100% - 20px);
        line-height: 20px;
        text-align: left;
    }

    .header-filter {
    }
</style>

<style>
    .smartTable .el-table .caret-wrapper {
        position: absolute;
        top: -5px;
        right: 5px;
    }

    .smartTable .el-table a {
        color: #3a8ee6
    }
</style>

<template>
    <el-table-column
            v-if="visible && !suppress"
            :label="label != null ? label : property"
            :property="property"
            :align="align"
            :sortable="sortable === true ? 'custom' : false"
            :showOverflowTooltip="true"
            :formatter="formatter ? formatter : defaultFormatter"
            :sortOrders="['ascending', 'descending']"
            :fixed="fixed"
            :width="columnWidth"
>
        <template v-slot:header="scope">
            <div class="header-label" :title="label">{{label}}</div>
            <div class="header-filter" @click="$event.stopPropagation()">
                <smart-filter :type="filterType"
                              :value="innerFilterValue"
                              @input="userSelectFilterValue"
                              :options="getOptionsForSelect()"
                />
            </div>
        </template>

        <template v-slot="scope">
            <slot :column="scope.column" :row="scope.row" :$index="scope.$index">
                <span v-html="scope.column.formatter(scope.row, scope.column, getValue(scope.row, scope.column), scope.$index)"></span>
            </slot>
        </template>
    </el-table-column>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { TableColumn } from "element-ui";
    import { ElementUIHorizontalAlignment } from "element-ui/types/component";
    import { DataTools } from "@/model/DataTools";
    import { SelectItem } from "@/model/SelectItem";
    import SmartTable from "./SmartTable.vue";
    import SmartFilter from "./SmartFilter.vue";
    import { SmartFilterType } from "./SmartFilterType";
    import { ISmartFilter } from "./ISmartFilter";
    import { ViewStateTools } from "@/model/ViewStates";
    import { FilterValue } from "./FilterValue";

    @Component({
        components: { TableColumn, SmartFilter }
    })
    export default class SmartColumn extends Vue
    {
        /** Column label */
        @Prop() label: string;

        /** Field name. You can also use its alias: property */
        @Prop() property: string;

        /** Alignment */
        @Prop() align: ElementUIHorizontalAlignment;

        /** Whether column can be sorted */
        @Prop({ default:true }) sortable: boolean;

        /** Function that formats content */
        @Prop() formatter: (row: object, column: { property:string }, value:any, index:number) => any;

        @Prop() filterType: SmartFilterType;

        @Prop({default:false}) suppress:boolean;

        private innerFilterValue = "";
        @Prop({ default:"" }) filterValue: any;
        @Watch("filterValue") onFilterValueChange(v) { this.innerFilterValue = v }

        @Prop() filterSelectValues: any[];
        @Prop({default: false}) alwaysVisible: boolean;
        @Prop() fixed;
        @Prop() width: string;

        get columnWidth() : string
        {
            if (this.width) {
                return this.width;
            }
            let table : SmartTable = <any>this.$parent.$parent;
            return ViewStateTools.getColumnWidth(table.$props.viewStateID, this.property);
        }

        get visible() : boolean
        {
            if (this.alwaysVisible) {
                return true;
            }
            let table : SmartTable = <any>this.$parent.$parent;
            let v = ViewStateTools.getTableColumnsVisibility(table.$props.viewStateID, null);
            return v === null || v.indexOf(this.property) >= 0;
        }

        created()
        {
            let table : { filters: { [name:string] : ISmartFilter } } = <any>this.$parent.$parent;

            if (typeof table.filters[this.property] !== 'undefined') {
                this.innerFilterValue = table.filters[this.property].value;
            }
        }

        defaultFormatter(row: object, column: { property:string }, value:any, index:number) : any
        {
            if (value instanceof Date) {
                return DataTools.dateToString(value, "hours-minutes-seconds");
            }

            if (value === null || typeof value === "undefined") {
                return "-";
            }

            return value.toString()
                        .split("&").join("&amp;")
                        .split("<").join("&lt;")
                        .split(">").join("&gt;");
        }

        getOptionsForSelect() : SelectItem[]
        {
            if (!this.filterType || !this.filterType.startsWith("select-")) {
                return null;
            }

            let r : SelectItem[];

            if (this.filterSelectValues) {
                r = this.filterSelectValues.map(value => {
                    let row = {};
                    DataTools.setDeepField(row, this.property, value);
                    let label = this.formatter ? this.formatter(row, this, value, null) : this.defaultFormatter(row, this, value, null);
                    return { value:FilterValue.fromNative(value), label };
                });
            }
            else {
                let table : SmartTable = <any>this.$parent.$parent;
                let data : any[] = (<any>table).data;
                if (!data) {
                    return [];
                }

                data = DataTools.removeDuplicateItems(data, (a, b) => DataTools.getDeepField(a, this.property) === DataTools.getDeepField(b, this.property));

                r = data.map(row => {
                    let value = DataTools.getDeepField(row, this.property);
                    let label = this.formatter ? this.formatter(row, this, value, null) : this.defaultFormatter(row, this, value, null);
                    return { value:FilterValue.fromNative(value), label };
                });
            }

            r.sort((a, b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0));

            return r;
        }

        userSelectFilterValue(e: any)
        {
            this.innerFilterValue = e;
            this.$parent.$emit("filter-change", <ISmartFilter>{ property:this.property, value:FilterValue.toNative(e), type:this.filterType });
        }

        getValue(row:any, column:{ property:string }) { return typeof column.property != "undefined" ? DataTools.getDeepField(row, column.property) : "" }
    }
</script>
