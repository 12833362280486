<style lang="scss" scoped>
    .hamburger-container {
        cursor: pointer;
    }

    .hamburger-container.is-active {
        transform: rotate(180deg);
    }
</style>

<template>
    <div :class="[{'is-active': isActive}, 'hamburger-container']" @click="toggleClick">
        <i class="fa fa-bars"></i>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'

    @Component
    export default class Hamburger extends Vue {
        @Prop({ default: false }) private isActive!: boolean;
        @Prop({ default: false }) private filter!: boolean;
        @Prop({ default: null }) private toggleClick!: () => void;
    }
</script>
