import {IRequester} from "@/model/ServerApi";
import {HistoryParameter} from "@/model/bindings/HistoryParameter";
import {HistoryLog} from "@/model/server/HistoryLog";

import {DataTools} from "@/model/DataTools";
import {HistoryRelatedParameter} from "@/model/bindings/HistoryRelatedParameter";

export class HistoryApi {
    constructor(private requester: IRequester) {
    }

    async list(param: HistoryParameter): Promise<HistoryLog[]> {
        return this.parseDate(await this.requester.post('/history/index', param));
    }

    async getRelated(param: HistoryRelatedParameter): Promise<HistoryLog[]> {
        return this.parseDate(await this.requester.post('/history/getrelated', param));
    }

    private parseDate(h: HistoryLog[]) {
        if (h) {
            h.forEach(x => {
                x.datetime = DataTools.parseServerDate(x.datetime)
            })
        }
        return h;
    }
}
