<style lang="css" scoped>
    >>> a.router-link-active > li > span { color: #409eff }
</style>

<style lang="scss">
    @import "src/styles/variables.scss";

    .el-submenu.is-active > .el-submenu__title {
        color: $subMenuActiveText !important;
    }

    .full-mode {
        .el-menu-item, .el-submenu__title {
            height: 40px;
            line-height: 35px;
        }

        .nest-menu .el-submenu > .el-submenu__title,
        .el-submenu .el-menu-item {
            background-color: #1f2d3d !important;

            &:hover {
                background-color: #001528 !important;
            }
        }
    }

    .simple-mode {
        &.first-level {
            .submenu-title-noDropdown {
                padding-left: 10px !important;
                position: relative;

                .el-tooltip {
                    padding: 0 10px !important;
                }
            }

            .el-submenu {
                overflow: hidden;

                & > .el-submenu__title {
                    padding-left: 10px !important;

                    .el-submenu__icon-arrow {
                        display: none;
                    }
                }
            }
        }
    }
</style>

<template>
    <div v-if="(!item.meta || !item.meta.hidden) && allowed" :class="['menu-wrapper', collapse ? 'simple-mode' : 'full-mode', {'first-level': !isNest}]">

        <app-link v-if="!children.length" :to="resolvePath(item.path)">

            <el-menu-item :index="item.path" :class="{'submenu-title-noDropdown': !isNest}">
                <i v-if="item.meta && item.meta.icon" class="fas fa-lightbulb"></i>
                <i v-else-if="item.meta && item.meta.icon" class="fas fa-lightbulb-slash"></i>
                <span v-if="item.meta && item.meta.title" slot="title">{{ $t(item.meta.title) }}</span>
                <span v-else-if="item.meta && item.meta.title" slot="title">{{ $t(item.meta.title) }}</span>
            </el-menu-item>

        </app-link>

        <el-submenu v-else :index="item.path" popper-append-to-body>
            <template slot="title">
                <i v-if="item.meta && item.meta.icon" class="fas fa-medal" ></i>
                <span v-if="item.meta && item.meta.title" slot="title">{{ $t(item.meta.title) }}</span>
            </template>
            <sidebar-item
                    v-for="child in item.children"
                    :key="child.path"
                    :is-nest="true"
                    :item="child"
                    :collapse="collapse"
                    class="nest-menu"
                    :base-path="resolvePath(item.path)"
            />
        </el-submenu>

    </div>
</template>

<script lang="ts">
    import path from 'path';
    import { RouteConfig } from 'vue-router';
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import AppLink from './Link.vue';
    import { PageGuard } from "@/model/PageGuard";

    @Component({
        // Set 'name' here to prevent uglifyjs from causing recursive component not work
        // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
        name: 'SidebarItem',
        components: {
            AppLink
        }
    })
    export default class SidebarItem extends Vue {
        @Prop({ required: true }) private item!: RouteConfig;
        @Prop({ default: false }) private isNest!: boolean;
        @Prop({ default: false }) private collapse!: boolean;
        @Prop({ default: '' })    private basePath!: string;

        get children() : RouteConfig[]
        {
            return this.item.children ? this.item.children.filter(x => !x.meta || !x.meta.hidden) : [];
        }

        get allowed() { return PageGuard.isAllow(this.item) }

        private resolvePath(routePath: string) {
            if (routePath.startsWith("/")) {
              return routePath;
            }
            return path.resolve(this.basePath, routePath);
        }
    }
</script>

