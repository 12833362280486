import {AuthModule} from "@/store/modules/auth";
import {SortOrder} from "element-ui/types/table";

export class ViewStateTools {
    public static getColumnWidth(viewStateID: string, name: string): string {
        if (!viewStateID || !AuthModule.viewStates[viewStateID]) {
            return '';
        }
        let viewState: TableViewState = JSON.parse(AuthModule.viewStates[viewStateID]);
        if (!viewState || !viewState.columns) {
            return '';
        }
        let colState = viewState.columns.find(x => x.name == name);
        if (!colState) {
            return '';
        }
        return colState.width;
    }

    public static saveColumnWidth(viewStateID: string, name: string, width: string): void {
        if (!viewStateID) {
            return;
        }
        let viewState = AuthModule.viewStates[viewStateID]
            ? <TableViewState>JSON.parse(AuthModule.viewStates[viewStateID])
            : <TableViewState>{};
        if (!viewState.columns) {
            viewState.columns = [];
        }
        let colState = viewState.columns.find(x => x.name == name);
        if (!colState) {
            viewState.columns.push({name, width});
        }
        else {
            colState.width = width;
        }
        AuthModule.setViewState({name: viewStateID, data: JSON.stringify(viewState)});
    }

    public static getTableSort(viewStateID: string, defaultProp: string, defaultOrder: SortOrder): { prop: string, order: SortOrder } {
        let defaultSort = defaultProp ? {prop: defaultProp, order: defaultOrder} : undefined;

        if (!viewStateID || !AuthModule.viewStates[viewStateID]) {
            return defaultSort;
        }
        let viewState: TableViewState = JSON.parse(AuthModule.viewStates[viewStateID]);
        if (!viewState || !viewState.sort || !viewState.sort.prop) {
            return defaultSort;
        }
        return viewState.sort;
    }

    public static saveTableSort(viewStateID: string, sort: { prop: string, order: SortOrder }): void {
        if (!viewStateID) {
            return;
        }
        let viewState = AuthModule.viewStates[viewStateID]
            ? <TableViewState>JSON.parse(AuthModule.viewStates[viewStateID])
            : <TableViewState>{};
        if (!viewState.sort || viewState.sort.prop !== sort.prop || viewState.sort.order !== sort.order) {
            viewState.sort = {prop: sort.prop, order: sort.order};
            AuthModule.setViewState({name: viewStateID, data: JSON.stringify(viewState)});
        }
    }

    public static getTablePageSize(viewStateID: string, defaultPageSize: number): number {
        if (!viewStateID || !AuthModule.viewStates[viewStateID]) {
            return defaultPageSize;
        }
        let viewState: TableViewState = JSON.parse(AuthModule.viewStates[viewStateID]);
        if (!viewState || !viewState.pageSize) {
            return defaultPageSize;
        }
        return viewState.pageSize;
    }

    public static saveTablePageSize(viewStateID: string, pageSize: number): void {
        if (!viewStateID) {
            return;
        }
        let viewState = AuthModule.viewStates[viewStateID]
            ? <TableViewState>JSON.parse(AuthModule.viewStates[viewStateID])
            : <TableViewState>{};
        viewState.pageSize = pageSize;
        AuthModule.setViewState({name: viewStateID, data: JSON.stringify(viewState)});
    }

    public static getTableColumnsVisibility(viewStateID: string, defaultProperties: string[]): string[] {
        if (!viewStateID || !AuthModule.viewStates[viewStateID]) {
            return defaultProperties;
        }
        let viewState: TableViewState = JSON.parse(AuthModule.viewStates[viewStateID]);
        if (!viewState || !viewState.visibility) {
            return defaultProperties;
        }
        return viewState.visibility;
    }

    public static saveTableColumnsVisibility(viewStateID: string, properties: string[]): void {
        if (!viewStateID) {
            return;
        }
        let viewState = AuthModule.viewStates[viewStateID]
            ? <TableViewState>JSON.parse(AuthModule.viewStates[viewStateID])
            : <TableViewState>{};
        viewState.visibility = properties;

        AuthModule.setViewState({name: viewStateID, data: JSON.stringify(viewState)});
    }

    public static getCustomValue<T>(viewStateID: string, defaultValue: T): T {
        if (!viewStateID || !AuthModule.viewStates[viewStateID]) {
            return defaultValue;
        }
        return JSON.parse(AuthModule.viewStates[viewStateID]);
    }

    public static saveCustomValue(viewStateID: string, value: any): void {
        if (!viewStateID) {
            return;
        }
        let tempObj = {};
        if (ViewStateTools.getCustomValue(viewStateID, tempObj) === value) {
            return;
        }
        AuthModule.setViewState({name: viewStateID, data: JSON.stringify(value)});
    }
}

export interface ViewStates {
    [name: string]: string;
}

interface TableViewState {
    columns: ColumnState[];
    sort: { prop: string, order: SortOrder };
    pageSize: number;
    visibility: string[];
}

interface ColumnState {
    name: string;
    width: string;
}
