import {IRequester} from "@/model/ServerApi";
import {ChargebacksProcessUploadedFileArg} from "@/model/bindings/ChargebacksProcessUploadedFileArg";
import {ProcessUploadedFileStandardResponse} from "@/model/bindings/ProcessUploadedFileStandardResponse";
import {TransactionChargeback} from "@/model/server/chargebacks/TransactionChargeback";
import {ChargebackGetStatisticResponse} from "@/model/bindings/ChargebackGetStatisticResponse";
import {ChargebackFieldVariants} from "@/model/server/ChargebackFieldVariants";
import {ChargebackGetStatisticArg} from "@/model/bindings/ChargebackGetStatisticArg";

export class ChargebackApi {
    constructor(private requester: IRequester) {
    }
    save(transactionID: string, chargeback: TransactionChargeback): Promise<void> {
        return this.requester.post('/chargeback/save?transactionID=' + transactionID, chargeback);
    }

    remove(transactionID: string): Promise<void> {
        return this.requester.post('/chargeback/remove?transactionID=' + transactionID);
    }

    getStatistic(arg: ChargebackGetStatisticArg): Promise<ChargebackGetStatisticResponse> {
        return this.requester.post('/chargeback/getstatistic', arg);
    }

    getVariants(gateway: number, reference_id:string, merchants: string[]): Promise<ChargebackFieldVariants> {
        return this.requester.post('/chargeback/getvariants', {gateway: gateway, reference_id:reference_id, merchants: merchants});
    }
}
