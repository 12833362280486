import {IRequester} from "@/model/ServerApi";
import {DataTools} from "@/model/DataTools";
import {CustomerTrustIndicatorFilterResponse} from "@/model/bindings/CustomerTrustIndicatorFilterResponse";
import {CustomerTrustIndicator} from "@/model/server/CustomerTrustIndicator";
import {CustomerTrustIndicatorFilter} from "@/model/bindings/CustomerTrustIndicatorFilter";

export class CustomerTrustIndicatorApi {
    constructor(private requester: IRequester) {
    }

    async getList(arg: CustomerTrustIndicatorFilter): Promise<CustomerTrustIndicatorFilterResponse> {
        let result: CustomerTrustIndicatorFilterResponse = await this.requester.post('/customertrustindicatorlist', arg);
        for (let t of result.data) {
            CustomerTrustIndicatorApi.fixElement(t);
        }
        return result;
    }

    private static fixElement(t: CustomerTrustIndicator): CustomerTrustIndicator {
        if (t.created) {
            t.created = DataTools.parseServerDate(t.created);
        }
        if (t.updated) {
            t.updated = DataTools.parseServerDate(t.updated);
        }
        return t;
    }
}
