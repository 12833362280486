export class FilterValue
{
    public static readonly NULL = "<null>";
    public static readonly EMPTY = "<empty>";
    public static readonly UNSET = "<unset>";

    public static toNative(v:any) : any
    {
        if (v === "") {
            return FilterValue.UNSET;
        }
        if (v === FilterValue.NULL) {
            return null;
        }
        if (v === FilterValue.EMPTY) {
            return "";
        }
        return v;

    }

    public static fromNative(v:any) : any
    {
        if (typeof v == "undefined" || v === FilterValue.UNSET) {
            return "";
        }
        if (v === null) {
            return FilterValue.NULL;
        }
        if (v === "") {
            return FilterValue.EMPTY;
        }
        return v;
    }
}
