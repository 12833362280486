<style lang="scss">
    @import "src/styles/variables.scss";

    .login-form {
        display: inline-block;
        .el-input {
            input {
                background: transparent;
                border: 0;
                -webkit-appearance: none;
                color: $lightGray;

                &:-webkit-autofill {
                    box-shadow: 0 0 0 1000px $loginBg inset !important;
                    -webkit-box-shadow: 0 0 0 1000px $loginBg inset !important;
                    -webkit-text-fill-color: #fff !important;
                }
            }
        }
    }
</style>

<style lang="scss" scoped src="./index.scss"></style>

<template>
    <el-form
        ref="form"
        :model="formData"
        class="login-form"
        label-position="left"
        @submit.native.prevent="submit"
    >

        <el-form-item prop="username" :rules="[{ required:true }]">
            <span class="icon-container"><i class="fa fa-user"></i></span>
            <el-input v-model="formData.username" type="text" :placeholder="$t('username')" :disabled="loading"/>
        </el-form-item>

        <el-form-item prop="password" :rules="[{ required:true }]">
            <span class="icon-container"><i class="fa fa-key"></i></span>
            <el-input v-model="formData.password" type="password" :placeholder="$t('password')"
                      @keyup.enter.native="submit" :disabled="loading"/>
        </el-form-item>

        <el-form-item>
            <el-button native-type="submit" :loading="loading" type="primary" style="width:100%">{{ $t('Login') }}
            </el-button>
        </el-form-item>

    </el-form>
</template>

<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {AuthModule} from '@/store/modules/auth'
    import {Form as ElForm} from 'element-ui'
    import {messages} from "./messages";
    import {AxiosError} from "axios";
    import {Messenger} from "@/model/Messenger";
    import {PageGuard} from "@/model/PageGuard";

    @Component({
        i18n: {messages}
    })
    export default class LoginForm extends Vue {
        formData = {
            username: '',
            password: ''
        };
        readonly warnDaysCount = 30;
        loading = false;

        @Prop({default: false}) redirectAfterLogin: boolean;

        checkPasswordExpiration(userTime: Date) {
            let diffTime = (new Date()).getTime() - userTime.getTime();
            let diffDays = Math.round(diffTime / (1000 * 3600 * 24));
            let message = "You haven't changed your password for " + diffDays + " days!";
            if (diffDays > this.warnDaysCount * 3) {
                Messenger.error(message);
            }
            else {
                if (diffDays > this.warnDaysCount * 2) {
                    Messenger.warning(message);
                } else {
                    if (diffDays > this.warnDaysCount) {
                        Messenger.info(message);
                    }
                }
            }
        }

        submit() {
            (<ElForm>this.$refs.form).validate(async valid => {
                if (!valid) {
                    return;
                }

                this.loading = true;
                try {
                    let data = await AuthModule.login(this.formData);
                    Messenger.clear();
                    if (data.user != null && data.user.password_change_time != null) {
                        this.checkPasswordExpiration(data.user.password_change_time);
                    }
                    if (this.redirectAfterLogin) {
                        await this.$router.push({path: <string>this.$route.query.redirect || PageGuard.getHomeUrl()});
                    }
                } catch (_e) {
                    let e: AxiosError = <AxiosError>_e;
                    if (e.response && e.response.data && (<any>e.response.data).message) {
                        Messenger.error(this.$t("Error"), (<any>e.response.data).message, 3);
                    }
                } finally {
                    this.loading = false;
                }
            })
        }
    }
</script>
