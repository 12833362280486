<style scoped>
    .smartTable {
        text-align: right;
    }

    .paginator{
        text-align: center;
        padding-top: 15px;
    }

    .smartTable >>> .el-table th>.cell div {
        display: block;
        padding: 0;
    }

    .smartTable >>> .el-table th {
        vertical-align: top;
    }

    .optionsButton {
        position: absolute;
        margin-top: -13px;
        margin-left: -13px;
        z-index: 4;
    }
</style>

<style>
    .el-table .el-table-row-highlighted {
        background-color: #e9f1fd
    }
</style>

<template>
    <div ref="container" v-loading="loading" class="smartTable">

        <el-button v-if="showOptions && !loading" class="optionsButton" type="info" size="mini" icon="el-icon-s-tools" circle @click="showOptionsDialog"></el-button>

        <el-table
            ref="table"
            :data="innerData"
            :row-key="rowKey"
            :current-row-key="innerSelectedRowKey"
            :default-sort="innerSortOptions"
            element-loading-text="Загрузка"
            border
            highlight-current-row
            :empty-text="$t('No data')"
            @current-change="onCurrentRowChange"
            @sort-change="onSortChange"
            @filter-change="onFilterChange"
            style="width:100%"
            @header-dragend="onColumnResize"
            @selection-change="checkChangedByUser"
            :row-class-name="rowClassName"
            :cell-class-name="cellClassName"
        >
            <el-table-column type="selection" width="35" v-if="innerChecks"/>
            <slot></slot>
            <smart-column :width="fillColumnWidth" :resizable="false"></smart-column>
        </el-table>

        <div v-if="!noPaginator" class="paginator">
            <el-pagination
                :current-page="innerCurrentPage + 1"
                @current-change="onCurrentPageChangedByUser"
                :page-sizes="pageSizes"
                :page-size="innerPageSize"
                @size-change="onPageSizeChanged"
                layout="total, sizes, prev, pager, next"
                :total="lazyTotalCount || lazyTotalCount === 0 ? lazyTotalCount : itemCountAfterFiltering">
            </el-pagination>
        </div>

        <table-options-dialog :visible.sync="visibleOptionsDialog" :columns="getColumnOptions()" :options="getColumnsVisibility()" @update:options="setColumnsVisibility"/>

    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Emit, Watch, PropSync } from 'vue-property-decorator';
    import { Table, Pagination } from 'element-ui';
    import { DefaultSortOptions } from "element-ui/types/table";
    import { ISmartFilter } from "./ISmartFilter";
    import { DataTools } from "@/model/DataTools";
    import { messages } from "./messages";
    import { ViewStateTools } from "@/model/ViewStates";
    import { FilterValue } from "@/components/smart-table/FilterValue";
    import SmartColumn from "@/components/smart-table/SmartColumn.vue";
    import TableOptionsDialog from "./TableOptionsDialog.vue";
    import { SelectItem } from "@/model/SelectItem";

    @Component({
        components: { SmartColumn, Table, Pagination, TableOptionsDialog },
        i18n: { messages }
    })
    export default class SmartTable extends Vue
    {
        // noinspection JSUnusedGlobalSymbols
        pageSizes = [5, 10, 20, 50, 100];

        @PropSync("checks") innerChecks: any[];

        checkChangedByUser(e:any[])
        {
            this.innerChecks = e.map(x => x[this.rowKey]);
        }

        restoreChecks(checks:any[]) : void
        {
            if (this.data && checks) {
                for (let row of this.data) {
                    if (checks.includes(row[this.rowKey])) {
                        (<any>this.$refs.table).toggleRowSelection(row, false);
                        (<any>this.$refs.table).toggleRowSelection(row, true);
                    }
                }
            }
        }

        @Prop() loading: boolean;

        innerData: any[] = null;
        @Prop() data: any[];
        @Watch("data") onDataChange(e)
        {
            this.innerData = e;
            this.applyFilterSortAndPage();
        }

        @Prop({ default:"id" }) rowKey: string;

        innerSelectedRowKey: any = null;
        @Prop() selectedRowKey: any;
        @Watch("selectedRowKey") onSelectedRowKeyChange(v) { this.innerSelectedRowKey = v }

        @Prop() noPaginator: boolean;
        @Prop() lazyTotalCount: number;

        innerPageSize: number = null;
        @Prop({ default:10 }) pageSize: number;
        @Watch("pageSize", { immediate:true }) onPageSizeChange(v) { this.innerPageSize = v }
        @Emit("update:pageSize") onPageSizeChanged(e:number)
        {
            ViewStateTools.saveTablePageSize(this.viewStateID, e);
            this.innerPageSize = e;
            this.applyFilterSortAndPage();
        }

        innerCurrentPage: number = null;
        @Prop({ default:0 }) currentPage: number;
        @Watch("currentPage") onCurrentPageChange(e)
        {
            this.innerCurrentPage = e;
            this.applyFilterSortAndPage();
        }

        onCurrentPageChangedByUser(e)
        {
            this.innerCurrentPage = e - 1;
            this.$emit("update:currentPage", e - 1);
            this.applyFilterSortAndPage();
        }

        innerSortOptions: DefaultSortOptions = null;
        @Prop() sortOptions: DefaultSortOptions;
        @Watch("sortOptions") onSortOptionsChange(e)
        {
            this.innerSortOptions = e;
            this.applyFilterSortAndPage();
        }
        onSortChange(e:DefaultSortOptions)
        {
            if (e.prop === null) {
                return;
            }
            ViewStateTools.saveTableSort(this.viewStateID, e);
            this.innerSortOptions = e;
            this.$emit("update:sortOptions", e);
            this.applyFilterSortAndPage();
        }

        private innerFilters: { [name:string] : ISmartFilter } = null;
        @Prop({ default:() => ({}) }) filters: { [name:string] : ISmartFilter };
        @Watch("filters") onFiltersChange(v) { this.innerFilters = v }

        @Prop() viewStateID: string;

        @Prop() showOptions: boolean;

        @Prop() rowClassName: (e:{ row:any, rowIndex:number }) => string;

        @Prop() cellClassName: (e:{ row:any, column:any, rowIndex:number, columnIndex:number }) => string;

        @Emit('ready') emitReady() {}

        itemCountAfterFiltering = 0;

        fillColumnWidth = 10;

        visibleOptionsDialog = false;

        getColumnOptions() : SelectItem[]
        {
            if (!this.$slots.default) {
                return [];
            }
            return this.$slots.default.filter(x => x.componentInstance && (<any>x.componentInstance).property)
                                      .map(x => ({ value:(<any>x.componentInstance).property, label:(<any>x.componentInstance).label, alwaysVisible:(<any>x.componentInstance).alwaysVisible }));
        }

        created()
        {
            this.innerData = this.data;
            this.innerSortOptions = this.sortOptions;
            this.innerPageSize = this.pageSize;
            this.innerCurrentPage = this.currentPage;
            this.innerSelectedRowKey = this.selectedRowKey;
            this.innerFilters = this.filters;

            if (this.viewStateID) {
                this.innerSortOptions = ViewStateTools.getTableSort(this.viewStateID, this.sortOptions ? this.sortOptions.prop : null, this.sortOptions ? this.sortOptions.order : null);
                this.$emit("update:sortOptions", this.innerSortOptions);

                this.innerPageSize = ViewStateTools.getTablePageSize(this.viewStateID, this.pageSize);
                this.$emit("update:pageSize", this.innerPageSize);
            }

            this.applyFilterSortAndPage();

            this.emitReady();

            setTimeout(() =>  this.fixFillColumnSize(null, null), 1);
        }

        onFilterChange(e: ISmartFilter)
        {
            if (e.value !== FilterValue.UNSET) {
                this.innerFilters[(<any>e).property] = e;
            }
            else                               {
                delete this.innerFilters[(<any>e).property];
            }

            this.$emit("update:filters", this.innerFilters);
            this.applyFilterSortAndPage();
        }

        onCurrentRowChange(newRow, oldRow)
        {
            this.innerSelectedRowKey = newRow ? newRow[this.rowKey] : null;
            this.$emit("update:selectedRowKey", this.innerSelectedRowKey);
        }

        private applyFilterSortAndPage()
        {
            if (this.lazyTotalCount || this.lazyTotalCount === 0) {
                return;
            }

            this.innerData = [];

            if (!this.data) {
                return;
            }

            for (let row of this.data)
            {
                let use = true;
                for (let property of Object.keys(this.innerFilters))
                {
                    if (!this.isUseRow(property, this.innerFilters[property], row)) { use = false; break; }
                }
                if (use) {
                    this.innerData.push(row);
                }
            }

            if (this.innerSortOptions && this.innerSortOptions.prop)
            {
                let prop = this.innerSortOptions.prop;
                let order = this.innerSortOptions.order === "ascending" ? 1 : -1;
                this.innerData.sort((a, b) => DataTools.compareUniversal(DataTools.getDeepField(a, prop), DataTools.getDeepField(b, prop)) * order);
            }

            this.itemCountAfterFiltering = this.innerData.length;

            if (!this.noPaginator)
            {
                let offset = this.innerCurrentPage * this.innerPageSize;
                this.innerData = this.innerData.slice(offset, offset + this.innerPageSize);
            }
        }

        private isUseRow(property:string, filter:ISmartFilter, row:any) : boolean
        {
            let value = DataTools.getDeepField(row, property);
            switch (filter.type) {
                case "select-one": return value === filter.value;
                case "select-many": return (<any[]>filter.value).indexOf(value) >= 0;
                case "string-contains": return value !== null && value.toString().indexOf(filter.value) >= 0;
                case "string-starts-with": return value !== null && value.toString().startsWith(filter.value);
                case "string-equals": return value !== null && value.toString() === filter.value.toString();

                case "int-range":
                case "float-range":
                    return value >= filter.value.min && value <= filter.value.max;
            }
            return true;
        }

        private onColumnResize(newWidth:number, oldWidth:number, column:{ property:string, id:string })
        {
            ViewStateTools.saveColumnWidth(this.viewStateID, column.property, newWidth + "px");
            this.fixFillColumnSize(newWidth, column);
        }

        private fixFillColumnSize(newWidth:number, column:{ property:string, id:string })
        {
            let container = <HTMLDivElement>this.$refs.container;

            if (!this.$slots.default) {
                return;
            }
            let columns = <Vue[]><any>this.$slots.default.filter(x => x.componentInstance && (<any>x.componentInstance).visible)
                                                         .map(x => x.componentInstance);
            let w = 0; for (let i = 0; i < columns.length; i++) {
                if (columns[i].$props.fixed !== "right") {
                    let allColumnElements = container.querySelectorAll(".el-table__header-wrapper > table.el-table__header > thead > tr > th");
                    let nativeColumnEl = allColumnElements[i + (this.innerChecks ? 1 : 0)];
                    w += !column || !nativeColumnEl.classList.contains(column.id) ? parseFloat(getComputedStyle(nativeColumnEl).width) : newWidth;
                }
                else {
                    w += parseFloat(columns[i].$props.width);
                }
            }

            this.fillColumnWidth = Math.max(10, parseFloat(getComputedStyle(container).width) - w - 1) - (this.innerChecks ? 35 : 0);
        }

        showOptionsDialog()
        {
            this.visibleOptionsDialog = true;
        }

        getColumnsVisibility() : string[]
        {
            return ViewStateTools.getTableColumnsVisibility(this.viewStateID, this.getColumnOptions().map(x => x.value));
        }

        setColumnsVisibility(properties:string[])
        {
            ViewStateTools.saveTableColumnsVisibility(this.viewStateID, properties);
        }
    }
</script>
