import {RouteConfig} from "vue-router";
import {AuthModule, AuthTools} from "@/store/modules/auth";
import {Role} from "@/model/server/Role";

export class PageGuard {
    static isAllow(page: RouteConfig): boolean {
        return !page.meta
            || !page.meta.roles
            || !!AuthModule.token && (page.meta.roles.length === 0 || page.meta.roles.some(x => x.split(",").every(y => AuthTools.hasRole(y))));

    }

    static getHomeUrl(): string {
        if (AuthTools.hasRole(Role.transactions)) {
            return "/transactions";
        }
        if (AuthTools.hasRole(Role.chargebacks)) {
            return "/chargebacks";
        }
        return "/profile";
    }
}
