import {Transaction} from "@/model/server/Transaction";
import {TransactionFilterResult} from "@/model/server/TransactionFilterResult";
import {TransactionFilter} from "@/model/server/TransactionFilter";
import {IRequester} from "@/model/ServerApi";
import {DataTools} from "@/model/DataTools";
import {TransactionSendResponse} from "@/model/bindings/TransactionSendResponse";
import {TransactionUpdateArg} from "@/model/bindings/TransactionUpdateArg";
import {TransactionChargebackTools} from "@/model/server/chargebacks/TransactionChargeback";
import {DetectorlistType} from "@/model/server/DetectorlistType";

export class TransactionServerApi {
    constructor(private requester: IRequester) {
    }

    send(transaction: Transaction): Promise<TransactionSendResponse> {
        return this.requester.post('/transactionlist/send', transaction);
    }

    update(arg: TransactionUpdateArg): Promise<void> {
        return this.requester.post('/transactionlist/update', arg);
    }

    async getTransactionList(data: TransactionFilter): Promise<TransactionFilterResult> {
        let result: TransactionFilterResult = await this.requester.post('/transactionlist/list', data);
        for (let t of result.transactions) {
            TransactionServerApi.fixTransaction(t);
        }
        return result;
    }

    async getListTriggeredTxn(data: TransactionFilter): Promise<TransactionFilterResult> {
        let result: TransactionFilterResult = await this.requester.post('/transactionlist/getlisttriggeredtxn', data);
        for (let t of result.transactions) {
            TransactionServerApi.fixTransaction(t);
        }
        return result;
    }

    async getTransaction(id: string): Promise<Transaction> {
        return TransactionServerApi.fixTransaction(await this.requester.get('/transactionlist/get?id=' + id));
    }

    loadFromRoyal(start_time: Date, end_time: Date): Promise<void> {
        return this.requester.post('/transactionlist/loadfromroyal?start_time=' + start_time.toISOString() + "&end_time=" + end_time.toISOString());
    }

    restoreRoyalID(start_time: Date, end_time: Date): Promise<void> {
        return this.requester.post('/transactionlist/restoreroyalid?start_time=' + start_time.toISOString() + "&end_time=" + end_time.toISOString());
    }

    remove(start_time: Date, end_time: Date): Promise<void> {
        return this.requester.post('/transactionlist/remove?start_time=' + start_time.toISOString() + "&end_time=" + end_time.toISOString());
    }

    stopLoadingFromRoyal(): Promise<void> {
        return this.requester.post('/transactionlist/stoploadingfromroyal');
    }

    allowLoadingFromRoyal(): Promise<void> {
        return this.requester.post('/transactionlist/allowloadingfromroyal');
    }
    async getBlacklistedProperties(id: string): Promise<DetectorlistType[]>{
        return this.requester.get('/transactionlist/getblacklistedproperties?id=' + id);
    }

    private static fixTransaction(t: Transaction): Transaction {
        if (t.customer) {
            if (t.customer.registration_datetime) {
                t.customer.registration_datetime = DataTools.parseServerDate(t.customer.registration_datetime);
            }
        }
        t.chargeback = TransactionChargebackTools.fixAfterLoading(t.chargeback);
        return t;
    }
}
