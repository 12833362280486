import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Route } from 'vue-router'
import { AuthModule } from '@/store/modules/auth'
import { PageGuard } from "@/model/PageGuard";

NProgress.configure({ showSpinner: false });

router.beforeEach((to: Route, from: Route, next: any) => {
    NProgress.start();
    if (AuthModule.token) {
        if (PageGuard.isAllow(to)) {
            if (to.path === '/login') { next({ path: PageGuard.getHomeUrl() }); NProgress.done(); }
            else {
                next();
            }
        }
        else {
            next({ path: PageGuard.getHomeUrl() });
        }
    }
    else {
        if (PageGuard.isAllow(to)) {
            next();
        }
        else {
            next(`/login?redirect=${to.path}`);
        }
    }
});

router.afterEach(() => {
    NProgress.done()
});
